.modal-fullscreen.modal-dialog {
  max-width: none;
  max-height: none;
  margin: 0;
}

.dropdown-toggle::after {
  display: none !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: #e9ecef !important;
  color: black !important;
  cursor: default;
}

.tooltip-inner {
  line-height: 1.25 !important;
}

@media (max-width: 767px) {
  .border {
    border: 0px solid !important;
  }
}

@media print {
  body,
  html {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .noprint {
    display: none !important;
  }

  .modal-open .modal {
    margin: 0;
    padding: 0;
    left: 0;
    top: 0;
    position: absolute !important;
    overflow: visible !important;
  }

  .modal-open .modal .row,
  .modal-open .modal .col {
    break-inside: auto !important;
  }
}
