/* Event Admin Dashboard CSS */

.com-event__select__value-container {
    min-width: 100px;
}
  
.com-event__btn {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: 150px;
  font-weight: 700;
  color: #828282;
  background-color: #e5e5e5;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.com-event__btn--blue-muted {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: 150px;
  font-weight: 700;
  color: #ffffff;
  background-color: #8cbeff;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.com-event__btn--blue-unselected {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: 150px;
  font-weight: 700;
  color: #244a85;
  background-color: #d7e3f4;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.com-event__btn--deep-blue {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: 150px;
  font-weight: 700;
  color: #ffffff;
  background-color: #0347ad;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.com-event-upload__container {
  height: 200px;
  border-radius: 5px;
  border: 2px solid #d0d3d7;
  overflow: hidden;
}

.com-event-image__container {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.com-event__img {
  width: 100%;
}

/* Event Cards */
.com-event-card__container {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  height: 260px;
  width: 250px;
  margin-bottom: 40px;
  border-radius: 5px;
  background-color: #f6f7f8;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  user-select: none;
  cursor: pointer;
}

.com-event-card__container:hover {
  background-color: #eaebec;
}

.com-event-card__container--selected {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  height: 260px;
  width: 250px;
  margin-bottom: 40px;
  border-radius: 5px;
  background-color: #f6f7f8;
  border: 2px solid #0347ad;
  box-shadow: 0px 2px 2px #0347ad;
  user-select: none;
  cursor: pointer;
}

/* Detail Title */

.com-detail__circle {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  color: #ffffff;
  background-color: #0347ad;
}

.com-event__textarea {
  height: 200px;
  width: 100%;
  padding: 15px;
  border: 2px solid #d0d3d7;
  border-radius: 4px;
}

.com-event__textarea:focus {
  box-shadow: 0 0 2px 1px #0347ad;
}

.com-event-desc-example__content {
  background-color: #ebf3fd;
  padding: 15px;
  border-radius: 4px;
}

.com-event__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: 2px solid #d0d3d7;
}

/* Detail DatePicker */
.com-event__date-input {
  height: 55px;
  padding-left: 10px;
  border: 2px solid #d0d3d7;
  border-radius: 4px;
}

.com-event__date-input:focus-within {
  box-shadow: 0 0 2px 1px #0347ad;
}

.com-event__date-wrapper input {
  border: none !important;
  height: 50px !important;
  padding-left: 10px !important;
  cursor: pointer;
}

/* Detail Select */
.com-event__select-container {
  width: 100%;
  height: 100%;
}

.com-event__select__control {
  max-height: 55px;
  border-color: none !important;
  box-shadow: none !important;
}

.com-event__select__value-container {
  padding-left: 15px !important;
}

.com-event__select__input-container {
  max-height: 50px;
}

/* Detail Select Multi */
.com-event__select-multi-container {
  width: 100%;
  height: 100%;
}

.com-event__select-multi__control:hover {
  border-color: #d0d3d7 !important;
}

.com-event__select-multi__control--is-focused {
  border-color: #d0d3d7 !important;
  box-shadow: 0 0 2px 1px #0347ad !important;
  outline: none !important;
}

.com-event__select-multi__value-container {
  padding: 15px 0 0 15px !important;
}

.com-event__select-multi__input-container {
  max-height: 50px;
}

/* Admin Event Success */

.com-event-success__container {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.com-event-success__link {
  width: fit-content;
  color: #3479f6;
  cursor: default;
}

.com-event-success__link:hover {
  text-decoration: underline;
}

/* Admin Event Table */
.com-event-table__btn {
  color: #7c7c80;
  cursor: pointer;
}

.com-event-table__btn:hover {
  color: #0347ad;
}

.com-event__date-picker-day {
  outline: none !important;
}

.com-event__date-picker-day.react-datepicker__day--selected {
  background-color: #0347ad;
}

.com-event__date-picker-day.react-datepicker__day--selected:hover {
  background-color: #3479f6;
}

.com-event__date-picker-day.react-datepicker__day--in-selecting-range {
  background-color: #3479f6 !important;
}

.com-event__date-picker-day.react-datepicker__day--in-range {
  background-color: #0347ad;
}

.com-event__date-picker-day.react-datepicker__day--in-range:hover {
  background-color: #3479f6;
}

.com-event__date-picker-day.react-datepicker__day--keyboard-selected {
  background-color: #0347ad;
}

.com-event__date-picker-day.react-datepicker__day--keyboard-selected:hover {
  background-color: #3479f6;
}

.com-event__date-picker__popper {
  z-index: 3 !important;
}

.com-event__date-picker__popper
  > div
  > .react-datepicker
  > .react-datepicker__triangle {
  left: -90px !important;
}

.com-event-form__date-picker__popper
  > div
  > .react-datepicker
  > .react-datepicker__triangle {
  left: -10px !important;
}

.com-event__date-icon {
  margin-right: 10px;
}

.com-event__input:focus {
  box-shadow: 0 0 2px 1px #0347ad;
}

.com-event__input:focus > span {
  color: #3479f6;
}

.com-event__input:focus-within > span {
  color: #3479f6;
}

.com-event__input-wrapper:focus-within > span {
  color: #3479f6 !important;
}

@media (max-width: 1200px) {
  .com-event-volunteer__modal-dialog {
    max-width: 95vw !important;
  }
}

.com-event-volunteer__confirm-backdrop {
  z-index: 1060 !important;
}
  