.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83vh; /* Adjust as needed */
  }
  
  .spinner {
    border: 4px solid #d93d3f; /* Red color with some transparency */
    border-top: 4px solid #FFFFFF; /* White color */
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }