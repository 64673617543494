@media (max-width: 991.98px) {
  .card-holder {
    min-width: 18rem;
    width: 100%;
    margin-bottom: 1rem;
  }
}
@media (min-width: 991.98px) {
  .card-holder {
    border-right: 1px solid #dee2e6;
    padding: 0 1rem;
  }
}
