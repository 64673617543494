body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.banner-fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner-fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.banner-image {
  /* background: url("./img/banner/banner-02.jpg"); */
  height: 110px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  background-color: #c2d69b;
}

.bannerimagetop {
  width: 100%;
  height: 124px;
  padding-bottom: 0%; /* Set the padding to maintain the aspect ratio */
  border-radius: 6px;
  object-fit: cover; /* Scale the image to cover the container */
  object-position: top; /* Position the top of the image */
}

.carousel-padding {
  padding-left: 35px;
  padding-right: 35px;
}

.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 35px; /* change this value to required width */
  text-align: center;
  filter: invert(60%);
  opacity: 0.5;
}

.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 35px; /* change this value to required width */
  text-align: center;
  filter: invert(60%);
  opacity: 0.5;
}

.xlargefontsize {
  font-size: 30px !important;
}

.largefontsize {
  font-size: 24px !important;
}

.defaultfontsize {
  font-size: 20px !important;
}

.defaultfontsize ul {
  list-style-type: disc; 
  padding-left: 20px;
}

.biggerfontsize {
  font-size: 18px !important;
}
.normalsmallfontsize {
  font-size: 16px !important;
}
.mediumfontsize {
  font-size: 14px !important;
}
.defaultstandardfontsize {
  font-size: 12px !important;
}
.defaultsmallfontsize {
  font-size: 10px !important;
}
.xsmallfontsize {
  font-size: 5px !important;
}

.normal-youtube-icon {
  font-size: 16px !important;
  color: red;
}

.youtubecontainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.eventnote {
  font-size: 20px !important;
  margin-top: 6px;
  margin-right: 5px;
}

.accountcircle {
  font-size: 20px !important;
  margin-top: 6px;
}
#redcircledot {
  background: #f00;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 5px;
  opacity: 80%;
}
.lineentry {
  text-overflow: ellipsis;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.twolineentry {
  text-overflow: ellipsis;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.cardzoom {
  transition: transform 0.2s; /* Animation */
}

.cardzoom:hover {
  transform: scale(
    1.01
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.cardhighlight {
  background-color: #fff;
}

.cardhighlight:hover {
  background-color: #fafafa;
}

.cardhighlight:hover #viewbutton {
  background-color: #dc3545;
  color: #fff;
}

.darkgreencolor {
  color: #458e3b;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.announcementpanel,
.prayerpanel {
  border: 1px solid #dddddd;
  background-color: #fff;
  color: #444444;
}
.announcementpanelheader,
.prayerpanelheader {
  color: #000000;
}

.announcementpaneldetails,
.prayerpaneldetails {
  color: #444444;
}

.announcementpanelblue,
.prayerpanelblue {
  border: 1px solid #eaf2fd;
  background-color: #eaf2fd;
  border: 1px solid #dddddd;
  color: #444444;
}

.announcementpanelheaderblue,
.prayerpanelheaderblue {
  color: #277af5;
}

.darkbluecolor {
  color: #004085;
}

.lightframecolor {
  border: 1px solid #dfe1e4;
}

.greyframecolor {
  border: 1px solid #ced4da;
}

.primaryframecolor {
  border: 1px solid #007bff;
}

.lightredframecolor {
  border: 1px solid #f5c2c7;
}

.whiteframecolor {
  border: 1px solid #fff;
}

.lightgreygreenframecolor {
  border: 1px solid #d3e5d0;
}

.redframecolor {
  border: 1px solid #d93d3f;
  background: #f8d7da;
}

.lightfilledredframecolor {
  border: 1px solid #f5c2c7;
  background: #f8d7da;
}

.greenframecolor {
  border: 1px solid #51a847;
}

.greenscanindicator {
  border-top: 3px solid #fff;
}

.whitescanindicator {
  border-top: 3px solid #fff;
}

.mycatholicsgred {
  color: #d93d3f;
}

.mycatholicsgblack {
  color: #222;
}

.mycatholicsgbgred {
  background-color: #d93d3f;
}

.mycatholicsggreen {
  color: #3d811e;
}

.iconcircle {
  background-color: #fff;
  /* border: 1px solid red; */
  height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
}

.appearslowly {
  animation: fadeIn 1.2s;
}


.text-black {
  color: #000;
}
.showvideowrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 20px;
  display: block;
  height: 200px;
}

.clickablebar {
  align-items: center;
  cursor: pointer;
}

.attendancecounter {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 2em;
  color: green;
  cursor: pointer;
}

.hidevideowrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  height: 30px;
}

.showvideoarea {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  /* height: 260px; */
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.hidevideoarea {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: none;
  height: 0px;
}

.dotlegend {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.roundcorner {
  border-radius: 8px;
  border: 1px solid #fff;
}

.roundcorneralertprimary {
  border-radius: 8px;
  border: 1px solid #cde5ff;
  color: #004085;
}
.roundtopcorners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.roundcornernocolor {
  border-radius: 8px;
}

.roundsmallcornernocolor {
  border-radius: 4px;
}

.ovalcornernocolor {
  border-radius: 15px;
}

.bigovalcornernocolor {
  border-radius: 20px;
}

.image-upload > input {
  visibility: hidden;
  width: 0;
  height: 0;
  display: none;
}

.image-upload {
  margin-top: -48px;
  margin-left: 140px;
  width: 50px;
}

.image-upload-icon {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.noHover {
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.pointerhover {
  cursor: pointer;
}
.pointerhover:before {
  content: " ";
  padding-left: "5px";
}

.pointerhover > span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.dangerunderline {
  text-decoration: underline;
}

.textunderline {
  padding-bottom: 0px;
  border-bottom: 1px dashed;
}

.textunderline:hover {
  padding-bottom: 0px;
  border-bottom: 1px solid;
}

.itemlist {
  color: #444;
  text-decoration: underline;
}

.itemlist:hover {
  text-decoration: underline;
  color: #dc3545;
}

.toplevelmenu {
  color: black;
}

.toplevelmenu:hover {
  color: #dc3545;
  text-decoration: underline;
}

.iconnormal {
  display: block;
  transform: rotate(0deg);
  transition: all 1s ease-in;
  -moz-transition: background 0.5s linear; /* Firefox 4 */
  -webkit-transition: background 0.5s linear; /* Safari and Chrome */
  -o-transition: background 0.5s linear; /* Opera */
  -ms-transition: background 0.5s linear; /* Explorer 10 */
}

.iconrotatesmooth {
  display: block;
  transform: rotate(90deg);
  transition: all 1s ease-in;
  -moz-transition: background 0.5s linear; /* Firefox 4 */
  -webkit-transition: background 0.5s linear; /* Safari and Chrome */
  -o-transition: background 0.5s linear; /* Opera */
  -ms-transition: background 0.5s linear; /* Explorer 10 */
}

.iconrotate {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.iconrotate45 {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bigclock {
  font-size: 24px;
  color: green;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.alignvertical {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dropdownwidthsm {
  max-width: 140px;
}
.dropdownwidthmd {
  max-width: 240px;
}
.dropdownwidthlg {
  max-width: 280px;
}

.dropdown-menu {
  min-width: 1rem;
}
.dropdown,
.dropup {
  position: relative;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #d93d3f;
  font-weight: bold;
}

.nav-link {
  color: #444;
}
.nav-link:hover {
  color: #d93d3f;
}
.react-bootstrap-table table {
  table-layout: auto;
}

.forcehide {
  height: 0px;
  overflow: hidden;
}

.red-bordered-circle {
  color: #3d811e;
  border: 3px solid #3d811e;
  background-color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
}

.black-bordered-circle {
  color: #444;
  border: 3px solid #444;
  background-color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
}

.center-content {
  float: left;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

#bottomcenter {
  height: 50px;
  position: fixed;
  bottom: 10%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}

.adjusticon {
  margin-top: -3px;
}

.seemore,
.seemore:hover {
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  color: #444;
  font-weight: 600;
  float: right;
}

.seemorelight,
.seemorelight:hover {
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  color: #000;
  float: right;
}

.seemoreunderline,
.seemoreunderline:hover {
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  /* color: #000; */
  text-decoration: underline;
  float: right;
}

.bordertopbottom {
  border-top: 8px solid #eee;
  border-bottom: 8px solid #eee;
}

.graypanel {
  background-color: #f7fafb;
}

.darkgraypanel {
  background-color: #f0f2f6;
}

.silhouette {
  filter: sepia(1) saturate(20) hue-rotate(180deg);
  /* filter: contrast(0) sepia(1) saturate(6) brightness(0.05) hue-rotate(80deg); */
  /* filter: contrast(100) hue-rotate(180deg); */
}

.lightspinner {
  color: #dadada;
}

.nophotoiconcolor {
  color: #aaa;
}

.wordbreakall {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.uen:hover {
  text-decoration: underline;
}

.notextdeco:hover {
  text-decoration: none !important;
}

.notextdeco {
  text-decoration: none !important;
}

.cardentry:hover {
  text-decoration: none !important;
  background-color: #f0f2f6;
}

.cardentry {
  border-radius: 8px;
  text-decoration: none !important;
}

.custom-control-lg .custom-control-label::before,
.custom-control-lg .custom-control-label::after {
  top: 0.1rem !important;
  left: -2rem !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
}

.scanheaderitemwidth {
  min-width: 130px;
}

.horizontalloader {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  user-select: none;
}

.yt-loader {
  -webkit-animation-name: horizontalProgressBar;
  animation-name: horizontalProgressBar;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  background: #ef534e;
  height: 6px;
  left: 0;
  top: 0;
  width: 0%;
  z-index: 0;
  position: relative;
}

.yt-loader:after {
  display: block;
  position: absolute;
  content: "";
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: #ef534e 1px 0 2px 1px;
  opacity: 0.1;
}

@keyframes horizontalProgressBar {
  0% {
    width: 0%;
  }
  30% {
    width: 50%;
  }
  40% {
    width: 60%;
  }
  80% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}

.react-datepicker__navigation--next {
  margin-top: 5px;
}
.react-datepicker__navigation--previous {
  margin-top: 5px;
}

/* Font Imports */

@font-face {
  font-family: "kollektif";
  src: url("./core/common/fonts/Kollektif.woff") format("woff");
}

@font-face {
  font-family: "providence-sans";
  src: url("./core/common/fonts/ProvidenceSans.woff") format("woff");
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.modal-dialog-xlg {
  width: 90% !important;
  max-width: none !important;
}



@keyframes wiggle {
  0% {transform: rotate(35deg);}
  12.5% {transform: rotate(-30deg);}
  25% {transform: rotate(25deg);}
  37.5% {transform: rotate(-20deg);}
  50% {transform: rotate(15deg);}
  62.5% {transform: rotate(-10deg)}
  75% {transform: rotate(5deg)}
  80% {transform: rotate(0);}  
  85% {transform: rotate(0);}  
  90% {transform: rotate(0);}  
  95% {transform: rotate(0);}  
  100% {transform: rotate(0);}  
}

.wiggle {
display: inline-block;
animation: wiggle 2.5s infinite;
}

.wiggle:hover {
animation: none;
}


@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px);
  }
  60% {
    transform: translateY(-2px);
  }
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes grow {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.grown {
  overflow: hidden;
  animation: grow 2s forwards;
}

@keyframes reveal {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

.reveal {
  animation: reveal 0.8s forwards;
}


.background-div {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Fpapalmass%2Fbackground.png?alt=media&token=db922ac9-3683-402f-a352-1dbefb46b07c');
  background-size: cover; /* Adjusts the image to cover the entire div */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
}

