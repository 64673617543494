/* Finance Dashboard Side Nav */
.finance-sidenav__container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 100%;
  background-color: #ffffff;
  z-index: 2;
}

.finance-sidenav__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
  cursor: default;
}

.finance-sidenav__section--selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
  cursor: default;
  color: #9B1ACC;
}

.finance-sidenv__section-text {
  font-size: 12px;
}

.finance-sidenav__section:hover {
  color: #ffffff !important;
  background-color: #E1BAF0;
}

.finance-sidenav__section:hover > .finance-sidenav__icon {
  color: #ffffff !important;
}

.finance-sidenav__icon {
  font-size: 36px !important;
  color: #808080;
}

.finance-sidenav__section--selected > .finance-sidenav__icon {
  color: #9B1ACC;
}