/* Finance Transaction Page */

.finance-transaction__container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #f6f8ff;
}

.finance-transaction__header {
  font-size: 28px;
  color: #9b1acc;
  margin-bottom: 20px;
}

.finance-transaction__table-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #7c7c80;
}

.finance-transaction__table-controls-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.finance-transaction__date-icon {
  margin-right: 10px;
}

.finance-transaction__date-picker__popper {
  z-index: 3 !important;
}

.finance-transaction__date-picker {
  display: flex;
  margin-right: 20px;
  user-select: none;
  cursor: pointer;
}

.finance-transaction__date-picker-day {
  outline: none !important;
}

.finance-transaction__date-picker-day.react-datepicker__day--selected {
  background-color: #9b1acc;
}

.finance-transaction__date-picker-day.react-datepicker__day--selected:hover {
  background-color: #e1baf0;
}

.finance-transaction__date-picker-day.react-datepicker__day--in-selecting-range {
  background-color: #e1baf0 !important;
}

.finance-transaction__date-picker-day.react-datepicker__day--in-range {
  background-color: #9b1acc;
}

.finance-transaction__date-picker-day.react-datepicker__day--in-range:hover {
  background-color: #e1baf0;
}

.finance-transaction__date-picker-day.react-datepicker__day--keyboard-selected {
  background-color: #9b1acc;
}

.finance-transaction__date-picker-day.react-datepicker__day--keyboard-selected:hover {
  background-color: #e1baf0;
}

.finance-transaction__date-live {
  user-select: none;
  cursor: pointer;
}

.finance-transaction__date-live--active {
  color: #fc7c80;
  user-select: none;
  cursor: pointer;
}

.finance-transaction__search-input {
  padding: 0 10px !important;
  color: #7c7c80;
}

.finance-transaction__search-input:focus {
  outline: none;
}

.finance-transaction__dropdown-item {
  color: #7c7c80 !important;
  text-decoration: none !important;
}

.finance-transaction__dropdown-item:hover {
  background-color: #e1baf0 !important;
  color: #ffffff !important;
}

.finance-transaction__download--hidden {
  display: none;
}

.finance-transaction__filter {
  display: none;
}

.finance-transaction__table-body {
  display: flex;
  margin-top: 20px;
}
