.create-qr-modal__dialog {
  width: 90% !important;
  max-width: none!important;
}

.create-qr-modal__container {
  padding: 30px !important;
  min-height: 650px;
}

.create-qr-modal-header {
  font-size: 28px;
  color: #9B1ACC;
  margin-bottom: 15px;
}

.create-qr-modal-status__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.create-qr-modal-status-bar__container {
  display: flex;
  justify-content: center;
  font-size: 16px;
  user-select: none;
  cursor: default;
}

.create-qr-modal-status__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin: 10px 0 5px 0;
  background-color: #7c7c80;
  color: #ffffff;
}

.create-qr-modal-status__icon--selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin: 10px 0 5px 0;
  background-color: #9B1ACC;
  color: #ffffff;
}

.create-qr-modal-status__header {
  display: flex;
  width: 33%;
  color: #7c7c80;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-qr-modal-status__header--selected {
  display: flex;
  width: 33%;
  color: #9B1ACC;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-qr-modal-status__divider {
  width: 31%;
  height: 2px;
  margin-top: 25px;
  background-color: #7c7c80;
}

.create-qr-modal-status__divider--selected {
  width: 31%;
  height: 2px;
  margin-top: 25px;
  background-color: #9B1ACC;
}

.create-qr-modal__form-details {
  display: flex;
  justify-content: space-between;
}

.create-qr-modal__side-section {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.create-qr-modal__side-section--border {
  display: flex;
  flex-direction: column;
  width: 45%;
  border-right: 1px solid #7c7c80;
}

.create-qr-modal__side-section--padding {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 0 0 0 10px;
}

.create-qr-modal__section {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
}

.create-qr-modal__section--border-row {
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 15px;
  border: 1px solid #7c7c80;
}

.create-qr-modal__confirmation {
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: row;
  margin-bottom: 15px;
  border: 1px solid #7c7c80;
}

.create-qr-modal__confirmation--row-space {
  height: 40px;
}

.create-qr-modal__confirmation--row-space > td {
  padding-right: 20px;
}

.create-qr-modal__section--margin {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 0 15px 15px;
}

.create-qr-modal__sub-section {
  display: flex;
}

@media (max-width: 800px) {
  .create-qr-modal__sub-section {
    display: flex;
    flex-wrap: wrap;
}
}

.create-qr-modal__section-title {
  margin-bottom: 5px;
  font-size: 18px;
  color: #7c7c80;
  cursor: default;
  user-select: none;
}

.create-qr-modal__section-sub-title {
  margin-bottom: 5px;
  font-size: 16px;
  color: #7c7c80;
  cursor: default;
  user-select: none;
}

.create-qr-modal__input-box {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  color: #7c7c80;
  outline: none;
}

.create-qr-modal__textarea {
  width: 100%;
  height: 150px;
  outline: none;
  resize: none;
  padding: 10px;
  color: #7c7c80;
}

.create-qr-modal__radio {
  display: flex;
  height: 30px;
  align-items: center;
}

.create-qr-modal__radio-input {
  margin-right: 10px;
}

.create-qr-modal__radio-text {
  margin-right: 25px;
  cursor: default;
  user-select: none;
}

.create-qr-modal__input-box--padding {
  width: 90%;
  height: 30px;
  color: #7c7c80;
  padding: 0 10px;
  outline: none;
}

.create-qr-modal__date-input {
  display: flex;
}

.create-qr-modal__date-input-box {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  color: #7c7c80;
  outline: none;
  cursor: default;
}

.create-qr-modal__date-icon {
  color: #7c7c80;
  margin-right: 15px;
}

.create-qr-modal__footer {
  display: flex;
  margin-top: 15px;
  justify-content: space-between !important;
  border-top: none !important;
}

.create-qr-modal__footer-btn {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 80px;
  padding: 0 5px;
  align-items: center;
  background-color: #7c7c80;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
}

.create-qr-modal__footer-btn--margin {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 80px;
  padding: 0 5px;
  margin-right: 15px;
  align-items: center;
  background-color: #7c7c80;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
}

.create-qr-modal__footer-btn-grp {
  display: flex;
}

.create-qr-modal__footer-btn--danger {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 80px;
  padding: 0 5px;
  margin-right: 15px;
  align-items: center;
  background-color: #dc3545;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
}

.create-qr-modal__footer-btn--success {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 80px;
  padding: 0 5px;
  align-items: center;
  background-color: #28a745;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
}

.create-qr-modal__select {
  width: 100%;
  outline: none;
  padding: 0 10px;
  height: 30px;
}

.create-qr-modal__detail-table {
  font-size: 14px;
  color: #7c7c80;
}

.create-qr-modal__confirmation-table {
  font-size: 14px;
  color: #7c7c80;
  min-width: 50%;
}

.create-qr-modal__confirmation-notice {
  font-size: 14px;
  color: #7c7c80;
  font-style: italic;
}

.create-qr-modal__confirmation-dialog {
  z-index: 1051 !important;
  background: rgba(0, 0, 0, 0.5);
  color: #7c7c80;
}

.create-qr-modal__confirmation-dialog-title {
  font-size: 20px;
  margin-bottom: 5px;
  color: #9B1ACC;
}

/* React Date Picker */
.create-qr-modal__date-picker-day {
  outline: none !important;
}

.create-qr-modal__date-picker-day.react-datepicker__day--selected {
  background-color: #9B1ACC;
}

.create-qr-modal__date-picker-day.react-datepicker__day--selected:hover {
  background-color: #E1BAF0;
}

.create-qr-modal__date-picker-day.react-datepicker__day--in-selecting-range {
  background-color: #E1BAF0 !important;
}

.create-qr-modal__date-picker-day.react-datepicker__day--in-range {
  background-color: #9B1ACC;
}

.create-qr-modal__date-picker-day.react-datepicker__day--in-range:hover {
  background-color: #E1BAF0;
}

.create-qr-modal__date-picker-day.react-datepicker__day--keyboard-selected {
  background-color: #9B1ACC;
}

.create-qr-modal__date-picker-day.react-datepicker__day--keyboard-selected:hover {
  background-color: #E1BAF0;
}

.create-qr-modal__time-picker.react-datepicker__time-list-item--selected {
  background-color: #9B1ACC !important;
}